export const getHAshtagsFromCaption = (caption: string) => {
    if (!caption) return [];
    var regexp = /\#\w\w+\s?/g;
    const results: any = caption?.match(regexp);
    return results;
}

export const comprobarFiltro = (misFiltros: any, post: any, hashtagsFromCaption: any) => {
    let mostrar = true;
    misFiltros.map((filtro: any) => {
        if (filtro.value1 !== "type-publish" && filtro.value1 !== "descripcion" && filtro.value3 !== "") {
            if (mostrar) {
                switch (filtro.value1) {
                    case "likes":
                        switch (filtro.value2) {
                            case "igual":
                                if (
                                    Number.parseInt(post.like_count?.toString().trim()) ===
                                    Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;
                            case "igual-mayor":
                                if (
                                    Number.parseInt(post.like_count?.toString().trim()) >=
                                    Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;
                            case "mayor":
                                if (
                                    Number.parseInt(post.like_count?.toString().trim()) >
                                    Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;
                            case "igual-menor":
                                if (
                                    Number.parseInt(post.like_count?.toString().trim()) <=
                                    Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;
                            case "menor":
                                if (
                                    Number.parseInt(post.like_count?.toString().trim()) <
                                    Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;

                            default:
                                mostrar = false;
                                break;
                        }
                        break;
                    case "comments":
                        switch (filtro.value2) {
                            case "igual":
                                if (
                                    Number.parseInt(
                                        post.comments_count.toString().trim()
                                    ) === Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;
                            case "igual-mayor":
                                if (
                                    Number.parseInt(post.like_count.toString().trim()) >=
                                    Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;
                            case "mayor":

                                if (
                                    Number.parseInt(
                                        post.comments_count.toString().trim()
                                    ) > Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;
                            case "igual-menor":
                                if (
                                    Number.parseInt(
                                        post.comments_count.toString().trim()
                                    ) <= Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;
                            case "menor":
                                if (
                                    Number.parseInt(
                                        post.comments_count.toString().trim()
                                    ) < Number.parseInt(filtro.value3.toString().trim())
                                ) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                                break;

                            default:

                                mostrar = false;
                                break;
                        }
                        break;
                    // case "type-publish":
                    //     switch (filtro.value2) {
                    //         case "image":
                    //             if (post.media_type === "IMAGE") {
                    //                 mostrar = true;
                    //             } else {
                    //                 mostrar = false;
                    //             }
                    //             break;
                    //         case "carrousel_album":
                    //             if (post.media_type === "CAROUSEL_ALBUM") {
                    //                 mostrar = true;
                    //             } else {
                    //                 mostrar = false;
                    //             }
                    //             break;
                    //         case "video":
                    //             if (post.media_type === "VIDEO") {
                    //                 mostrar = true;
                    //             } else {
                    //                 mostrar = false;
                    //             }
                    //             break;

                    //         default:
                    //             mostrar = false;
                    //             break;
                    //     }
                    //     break;
                    case "hashtag":
                        const val = filtro.value3.toString().charAt(0);
                        const index = hashtagsFromCaption?.findIndex(
                            (value: string) =>
                                value.trim() ===
                                (val === "#"
                                    ? filtro.value3.toString()
                                    : `#${filtro.value3.toString()}`
                                ))
                        if (index > 0) {
                            mostrar = true;
                        } else {
                            mostrar = false;
                        }
                        break;
                    // case "descripcion":
                    //     if (filtro.value2.trim() === "contiene") {
                    //         if (post.caption.trim().length > 0) {
                    //             mostrar = true;
                    //         } else {
                    //             mostrar = false;
                    //         }
                    //     }
                    //     if (filtro.value2.trim() === "no-contiene") {
                    //         if (post.caption.trim().length === 0) {
                    //             mostrar = true;
                    //         } else {
                    //             mostrar = false;
                    //         }
                    //     }
                    //     break;
                    default:
                        mostrar = false;
                        break;
                }
            }
        } else {
            if (filtro.value1 === "type-publish" || filtro.value1 === "descripcion") {
                if (mostrar) {
                    switch (filtro.value1) {
                        case "type-publish":
                            switch (filtro.value2) {
                                case "image":
                                    if (post.media_type === "IMAGE") {
                                        mostrar = true;
                                    } else {
                                        mostrar = false;
                                    }
                                    break;
                                case "carrousel_album":
                                    if (post.media_type === "CAROUSEL_ALBUM") {
                                        mostrar = true;
                                    } else {
                                        mostrar = false;
                                    }
                                    break;
                                case "video":
                                    if (post.media_type === "VIDEO") {
                                        mostrar = true;
                                    } else {
                                        mostrar = false;
                                    }
                                    break;

                                default:

                                    mostrar = false;
                                    break;
                            }
                            break;

                        case "descripcion":
                            if (filtro.value2.trim() === "contiene") {
                                if (post.caption.trim().length > 0) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                            }
                            if (filtro.value2.trim() === "no-contiene") {
                                if (post.caption.trim().length === 0) {
                                    mostrar = true;
                                } else {
                                    mostrar = false;
                                }
                            }
                            break;
                        default:
                            mostrar = false;
                            break;
                    }
                }
            }
        }
    });
    return mostrar;
}

export const obtenerEngagementRate = (data: any) => {
    const { media, followers_count } = data;
    let media_Array = [];
    /* Get extra details from last media */
    let suma_likes = 0;
    let suma_comentarios = 0;
    /* Go over each recent media post */
    let fin;
    if (media.length < 20) {
        fin = media.length;
    } else {
        fin = 20;
    }
    let sumaPostTotal = 0;
    for (let i = 0; i < fin; i++) {
        suma_likes += media[i].like_count;
        suma_comentarios += media[i].comments_count;
        sumaPostTotal += (media[i].like_count + media[i].comments_count);
        media_Array.push(media[i]);
    }
    let engagement_suma = ((sumaPostTotal / fin) * 100) / followers_count;
    // for (let i = 0; i < fin; i++) {
    //     suma_likes += media[i].like_count;
    //     suma_comentarios += media[i].comments_count;
    //     let suma = (media[i].like_count + media[i].comments_count)/ followers_count;
    //     suma_engagement_rate += suma * 100;
    //     media_Array.push(media[i]);
    // }
    let promedio_likes = Math.round(suma_likes / fin);
    let promedio_comentarios = Math.round(suma_comentarios / fin);
    let promedio_engagement_rate = engagement_suma.toFixed(2);
    return {
        likes: promedio_likes,
        comentarios: promedio_comentarios,
        engagement: promedio_engagement_rate
    };
}


export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function getRandomWordFromHashtags(arr: any, n: any) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

export const getMoreRepitedHashtasgFunction = (array: Array<string>, n: number) => {
    array = array.sort();
    let contador = 1;
    let aux = array[0];
    let arrayRetur = []

    for (let i = 0; i < array.length; i++) {
        if (aux === array[i]) {
            contador++;
        } else {
            arrayRetur.push({
                value: aux,
                count: contador
            })
            aux = array[i];
            contador = 1;
        }
    }

    arrayRetur = arrayRetur.sort((a, b) => b.count - a.count);
    return arrayRetur.slice(0, n);

}

export const abreviarNumeros = (value: number) => {
    var bN = 0;
    var sf = ["", "K", "M", "B", "T", "c", "q", "s"];
    while (value > 999) {
        value /= 1000;
        bN++;
    }
    let number = Number.parseFloat(value.toString()).toPrecision(3);
    return number + sf[bN];
}