import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../assets/icons/ri_close-fill.svg";
import hashIcon from "../../assets/icons/hash_edit.svg";
import hashIconDelete from "../../assets/icons/hash_delete.svg";
import { IStateModel, PropsModalCreateProyect } from "../../interfaces/interface";
import { addHashtag, deleteProyect } from "../../redux/actions/proyectsAction";
import { useState } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};
Modal.setAppElement("#root");

const ModalEditProyect: React.FC<PropsModalCreateProyect> = ({
  modalIsOpen,
  closeModal,
}) => {
  const dispatch:any = useDispatch();
  const [name, setName] = useState("");
  const [isOpenModalError, setisOpenModalError] = useState(false);

  const openErrorModal = () => {
    setisOpenModalError(true);
  };
  const closeErrorModal = () => {
    setisOpenModalError(false);
  };

  const {
    auth: { accessToken, refreshToken },
    proyects: { error, currentProyect },
  } = useSelector((state: IStateModel) => state);

  const updateProyect = () => {
    currentProyect!.name = name;
    dispatch(
      addHashtag(
        "",
        currentProyect,
        accessToken!,
        refreshToken!,
        true,
        closeModal
      )
    );
  };

  const dispatchDeleteProyect = () => {
    dispatch(
      deleteProyect(
        currentProyect!._id,
        accessToken!,
        refreshToken!,
        closeErrorModal,
        closeModal
      )
    );
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Editar Proyecto"
      >
        <div className="mh__modalCreateProyect-create">
          <div className="container-imgClose">
            <img
              src={closeIcon}
              alt="Cerrar ventana"
              onClickCapture={() => closeModal()}
            />
          </div>
          <div className="container-imgHash">
            <img src={hashIcon} alt="Más Hashtag" />
          </div>
          <h3>Editar proyecto</h3>
          <label htmlFor="name">Nombre de tu proyecto</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Nombre"
            defaultValue={currentProyect?.name}
            //   value={name}
            onChangeCapture={(e: any) => setName(e.target.value)}
          />
          {error ? (
            <div className="mh__modalCreateProyect-create-error">
              <p>{error}</p>
            </div>
          ) : (
            <></>
          )}
          <p
            className="mh_modalEdit-delete"
            onClickCapture={() => openErrorModal()}
          >
            Eliminar Proyecto
          </p>
          <div className="mh__modalCreateProyect-create-row">
            <div onClickCapture={() => closeModal()}>
              <p>CANCELAR</p>
            </div>
            <div onClickCapture={() => updateProyect()}>
              <p>GUARDAR CAMBIOS</p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalError}
        onRequestClose={closeErrorModal}
        style={customStyles}
        contentLabel="Confirmar Eliminación Proyecto"
      >
        <div className="mh__modalCreateProyect-create">
          <div className="container-imgClose">
            <img
              src={closeIcon}
              alt="Cerrar ventana"
              onClickCapture={() => closeErrorModal()}
            />
          </div>
          <div className="container-imgHash">
            <img src={hashIconDelete} alt="Más Hashtag" />
          </div>
          <h3>¿Eliminar Proyecto?</h3>

          <p className="mh_modalEdit-msgdelete">
            Al Eliminar el proyecto se eliminarán todos sus hashtags
            seleccionados
          </p>
          <div className="mh__modalCreateProyect-create-row">
            <div onClickCapture={() => closeErrorModal()}>
              <p>CANCELAR</p>
            </div>
            <div
              className="mh__modalEdit-btnDelete"
              onClickCapture={() => dispatchDeleteProyect()}
            >
              <p>Eliminar Proyecto</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalEditProyect;
