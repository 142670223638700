import { ActionModel, IProjects, ProyectModel } from "../../interfaces/interface";
import {
    CREATE_PROYECT,
    CREATE_PROYECT_ERROR,
    CREATE_PROYECT_SUCCESS,
    GET_PROYECTS,
    GET_PROYECTS_ERROR,
    GET_PROYECTS_SUCCESS,
    CHANGE_CURRENT_PROYECT,
    ADD_HASHTAG_SUCCESS,
    REMOVE_HASHTAG_SUCCESS,
    CERRAR_SESION,
    CHANGE_LIST_STATE_PROYECT,
    DELETE_HASHTAG,
    DELETE_HASHTAG_SUCCESS,
    DELETE_HASHTAG_ERROR
} from "../../types/types";

const initialData: IProjects = {
    listProyects: [],
    fetching: false,
    fetchingGet: false,
    error: null,
    fetchingDelete: false,
    currentProyect: null,
}

export const proyectsReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case CREATE_PROYECT:
            return {
                ...state,
                fetching: true
            };
        case CREATE_PROYECT_SUCCESS:

            return {
                ...state,
                fetching: false,
                error: null,
                listProyects: [...state.listProyects, action.payload]
            };
        case CREATE_PROYECT_ERROR:
            return {
                ...state,
                error: action.payload,
                fetching: false
            };
        case GET_PROYECTS:
            return {
                ...state,
                fetchingGet: true
            };
        case GET_PROYECTS_SUCCESS:

            return {
                ...state,
                fetchingGet: false,
                error: null,
                listProyects: action.payload
            };
        case GET_PROYECTS_ERROR:
            return {
                ...state,
                error: action.payload,
                fetchingGet: false
            };
        case CHANGE_CURRENT_PROYECT:
            return {
                ...state,
                currentProyect: (state.listProyects.filter((proyect: ProyectModel) => proyect._id === action.payload))[0]
            };
        case ADD_HASHTAG_SUCCESS:
        case REMOVE_HASHTAG_SUCCESS:
            return {
                ...state,
                currentProyect: action.payload
            }
        case CHANGE_LIST_STATE_PROYECT:
            return {
                ...state,
                currentProyect: action.payload
            }
        case CERRAR_SESION:
            return initialData;
        case DELETE_HASHTAG:
            return {
                ...state,
                fetchingDelete: true
            }
        case DELETE_HASHTAG_SUCCESS:
            return {
                ...state,
                fetchingDelete: false,
                errorDelete: null
            }
        case DELETE_HASHTAG_ERROR:
            return {
                ...state,
                fetchingDelete: false,
                error: action.payload
            }

        default:
            return state;
    }
}