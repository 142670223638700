import { AppRouter } from "./routers/AppRouter";
import { Provider } from "react-redux";
import generateStore from "./redux/store/store";

function MasHAshtagsApp() {
  let store = generateStore();
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default MasHAshtagsApp;
