import { ActionModel, IHashtagsState } from "../../interfaces/interface";
import {
    GET_HASTAGS,
    GET_HASTAGS_SUCCESS,
    GET_HASTAGS_EMPTY_SUCCESS,
    GET_HASTAGS_ERROR,
    GET_HASHTAG_INFO,
    GET_HASHTAG_INFO_ERROR,
    GET_HASHTAG_INFO_SUCCESS,
    CERRAR_SESION,
    RESET_LIST_HASHTAGS,
    HASHTAGS_FROM_CAPTION,
    COUNTER_SEARCHS_ERROR,
} from "../../types/types";

const initialData: IHashtagsState = {
    hashtagsSelected: [],
    fetching: false,
    fetchingInfo: false,
    listHashtags: [],
    error: null,
    currentHashtag: null,
    isEmptyList: false,
    hashtagsFromCaption: [],
    automatic: false,
    counterSearchError: false,
    errorInfo: null,
}

export const hashtagReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case GET_HASTAGS:
            return {
                ...state,
                fetching: true,
                automatic: false,
                counterSearchError: false
            };
        case GET_HASTAGS_SUCCESS:
            // case REMOVE_ALL_HASHTAG_SUCCESS:
            return {
                ...state,
                listHashtags: action.payload.data,
                fetchingInfo: action.payload.fetching,
                isEmptyList: false,
                fetching: false,
                error: null,
                counterSearchError: false
            };
        case GET_HASTAGS_EMPTY_SUCCESS:
            return {
                ...state,
                listHashtags: action.payload,
                fetching: false,
                error: null,
                isEmptyList: true,
                automatic: false,
                counterSearchError: false
            }
        case GET_HASTAGS_ERROR:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                isEmptyList: false,
                automatic: false,
                counterSearchError: false
            };
        case GET_HASHTAG_INFO:
            return {
                ...state,
                fetchingInfo: true,
                errorInfo: null,
                hashtagsFromCaption: [],
                automatic: false,
                counterSearchError: false
            };
        case GET_HASHTAG_INFO_ERROR:
            return {
                ...state,
                fetchingInfo: false,
                errorInfo: true,
                automatic: false,
                counterSearchError: false,
                currentHashtag: null
            };
        case GET_HASHTAG_INFO_SUCCESS:
            // return state;
            return {
                ...state,
                fetchingInfo: false,
                errorInfo: null,
                currentHashtag: action.payload.data,
                automatic: false,
                counterSearchError: false
            };
        case HASHTAGS_FROM_CAPTION:
            return {
                ...state,
                hashtagsFromCaption: action.payload,
                automatic: false,
                counterSearchError: false
            }
        case RESET_LIST_HASHTAGS:
            return {
                ...state,
                listHashtags: action.payload,
                automatic: false,
                counterSearchError: false
            }
        case COUNTER_SEARCHS_ERROR:
            return {
                ...state,
                counterSearchError: true
            }
        case CERRAR_SESION:
            return initialData

        default:
            return state;
    }
}