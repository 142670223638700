import axios from 'axios';
import clienteAxios from '../../config/axios'
import { auditApi } from '../../constants/constants';
import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REFRESH_TOKEN,
    GET_ACCOUNTS_FACEBOOK,
    GET_ACCOUNTS_FACEBOOK_ERROR,
    GET_ACCOUNTS_FACEBOOK_SUCCESS,
    GET_ACCOUNTS_FACEBOOK_MULTIPLEPAGE_SUCCESS,
    CERRAR_SESION,
    REFRESH_TOKEN_ERROR,
    RECOVER_PASSWORD,
    RECOVER_PASSWORD_ERROR,
    RECOVER_PASSWORD_SUCCESS,
    // GET_USER_DATA,
    GET_USER_DATA_ERROR,
    GET_USER_DATA_SUCCESS,
    UPDATE_USER_ACCOUNT_DATA,
    UPDATE_USER_ACCOUNT_DATA_SUCCESS,
    UPDATE_USER_ACCOUNT_DATA_ERROR,
    LINKED_ACCOUNT,
    UNLINK_USER_ACCOUNT,
    UNLINK_USER_ACCOUNT_ERROR,
    UNLINK_USER_ACCOUNT_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS

} from '../../types/types'
import { getInitialProyects } from '../actions/proyectsAction';


export const startLogin = (email: string, password: string) => {
    // const history = useNavigate();
    return async (dispatch: any) => {
        dispatch({
            type: LOGIN
        })
        try {
            const resp = await clienteAxios.post('auth/login', {
                email,
                password
            });
            dispatch({
                type: LOGIN_SUCCESS,
                payload: resp.data
            })


            getUserData(resp.data.accessToken, dispatch, resp.data.refreshToken);
            // Obtener datos de cuenta relacionada
            const resp2 = await clienteAxios.get('accounts/', {
                headers: {
                    'Authorization': `Bearer ${resp.data.accessToken}`
                }
            });
            if (!resp2.data) {

            } else {

                dispatch({
                    type: LINKED_ACCOUNT,
                    payload: resp2.data
                })

                localStorage.setItem("linkedAccount", JSON.stringify(resp2.data));
            }
        } catch (error:any) {
            console.log("====> Error al iniciar sesión");
            console.log(error);
            console.log(error.message);
            dispatch({
                type: LOGIN_ERROR,
                payload: "Error al Iniciar Sesión. Revisa tus credenciales"
            })
        }
    }
}

export const verificarVinculacionCuenta = async (dispatch: any, accessToken: string) => {
    // return async (dispatch: any) => {
    try {
        const resp2 = await clienteAxios.get('accounts/', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        if (!resp2.data) {
            localStorage.removeItem("linkedAccount");
            dispatch({
                type: LINKED_ACCOUNT,
                payload: null
            })
        } else {
            dispatch({
                type: LINKED_ACCOUNT,
                payload: resp2.data
            })
            localStorage.setItem("linkedAccount", JSON.stringify(resp2.data));
        }
    } catch (error) {
        console.log("=== ERROR AL OBTENER CUENTA")
        console.log(error);
        localStorage.removeItem("linkedAccount");
    }
    // }
}

export const refreshToken = async (dispatch: any, refreshToken: String,) => {
    return clienteAxios.post('auth/refresh', {
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`
        }
    }).then((resp) => {
        dispatch({
            type: REFRESH_TOKEN,
            payload: {
                accessToken: resp.data.accessToken,
                refreshToken: resp.data.refreshToken
            }
        })
        localStorage.setItem("refreshToken", resp.data.refreshToken)
        localStorage.setItem("accessToken", resp.data.accessToken)
    }).catch(error => {
        console.log("====> Error al Refrescar Token");
        console.log(error.message);
        dispatch({
            type: REFRESH_TOKEN_ERROR,
            payload: "Error al Refrescar Token"
        })
    });
}

export const accountsFacebook = (accessToken: string, dataFacebook: any, accessTokenWeb: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: GET_ACCOUNTS_FACEBOOK
        })
        try {
            const pages = await axios.get(`https://graph.facebook.com/v12.0//me/accounts?access_token=${accessToken}`);
            if (pages.data.data <= 0) {
                // no accounts no instagram
                dispatch({
                    type: GET_ACCOUNTS_FACEBOOK_ERROR,
                    payload: "No tienes una Cuenta de instagram o tu cuenta es privada"
                })
            } else if (pages.data.data.length === 1) {
                const { access_token, id } = pages.data.data[0];
                validarCuenta(access_token, id, dispatch, dataFacebook, accessTokenWeb)
            } else {
                // Varias paginas (Hacer dispatch)
                // setPages(pages.data.data);
                dispatch({
                    type: GET_ACCOUNTS_FACEBOOK_MULTIPLEPAGE_SUCCESS,
                    payload: {
                        fbPages: pages.data.data,
                        dataFb: dataFacebook
                    }
                })
            }

        } catch (error:any) {
            console.log("====> Error al obtener cuentas");
            console.log(error.message);
            dispatch({
                type: GET_ACCOUNTS_FACEBOOK_ERROR,
                payload: "Error al Obtener cuenta de Facebook"
            });
            setTimeout(() => {
                dispatch({
                    type: GET_ACCOUNTS_FACEBOOK_ERROR,
                    payload: null
                })
            }, 6000);
        }

    }
}
export const logOut = (history: any) => {
    return async (dispatch: any) => {
        localStorage.removeItem("linkedAccount");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("profileData");
        dispatch({
            type: CERRAR_SESION
        });
        history('/login',{replace: true});

    }
}

export const validarCuenta = async (access_token: string, id: string, dispatch: any, dataFacebook: any, accessToken: string) => {
    dispatch({
        type: GET_ACCOUNTS_FACEBOOK
    })
    try {
        const instagramValido = await axios.get(`https://graph.facebook.com/v12.0/${id}?fields=instagram_business_account&access_token=${access_token}`);
        
        if (!instagramValido.data.instagram_business_account) {
            // Error no tiene ninguna cuenta de instagram
            return dispatch({
                type: GET_ACCOUNTS_FACEBOOK_ERROR,
                payload: "No tienes una cuenta de Instagram o tu cuenta es privada"
            });
        }
        
        

        // Cambiar a token de larga duración
        // const new_token = await axios.get(`https://graph.facebook.com/v12.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${facebookCredential.appkey}&client_secret=${facebookCredential.appKeySecret}&fb_exchange_token=${access_token}`);
        
        let new_token = await axios.get(`${auditApi.baseURL}/long_token/${access_token}`);
        

        dataFacebook.idInstagram = instagramValido.data.instagram_business_account.id;
        // dataFacebook.access_token = new_token.data.access_token;
        dataFacebook.access_token = new_token.data.access_token;
        let respuesta = await axios.get(`https://graph.facebook.com/v12.0/${dataFacebook.idInstagram}?fields=biography,ig_id,followers_count,follows_count,media_count,name,profile_picture_url,username,website&access_token=${dataFacebook.access_token}`);
        
        let nuevoUsuarioData = {
            instagram_id: dataFacebook.idInstagram,
            username: respuesta.data.username,
            full_name: respuesta.data.name,
            biography: respuesta.data.biography,
            website: respuesta.data.website,
            followers: respuesta.data.followers_count,
            following: respuesta.data.follows_count,
            uploads: respuesta.data.media_count,
            profile_picture_url: respuesta.data.profile_picture_url,
            average_engagement_rate: null
        };
        const nuevoUsuario = await axios.post(`${auditApi.baseURL}/v2/usuarios`, nuevoUsuarioData);
        // nuevoUsuairo.status === 202 : YA EXISTE TOCA ACTUALZIAR CON ==> await axios.put(`${dataConfig.urlApi}/v2/usuarios/${nuevoUsuario._id}`, nuevoUsuarioData); 
        let userData = nuevoUsuario.data;
        if (nuevoUsuario.status === 202) {
            

            // El usuario ya existe hay que actualizar
            const respPost = await axios.put(`${auditApi.baseURL}/v2/usuarios/${nuevoUsuario.data.usuario._id}`, nuevoUsuarioData);
            

            userData = respPost.data;
        }

        let nuevoTokenDatos = {
            token: dataFacebook.ACCESS_TOKEN,
            instagram_id: dataFacebook.idInstagram,
            user_ig: userData.id,
            ultima_fecha_actualizacion: new Date()
        }

        // Obtener token
        const respToken = await axios.get(`${auditApi.baseURL}/token/${userData._id}`);

        if (respToken.data.mensaje) {
            // Token no existe crear uno nuevo
            await axios.post(`${auditApi.baseURL}/v2/token`, nuevoTokenDatos)

        } else {
            // Actualizar token
            await axios.post(`${auditApi.baseURL}/token/renovar/${respToken.data._id}`, nuevoTokenDatos);
            
            
        }

        // POST A ACCOUNT DE NUESTRA API
        var expiredAt = new Date();
        // add a day
        expiredAt.setDate(expiredAt.getDate() + 60);

        const resp3 = await clienteAxios.post('accounts/',
            {
                accessToken: dataFacebook.access_token,
                instagramId: dataFacebook.idInstagram,
                image: dataFacebook.urlImg,
                name: dataFacebook.name,
                expiredAt
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
        // HACER RESPUESTA 3 DISPATCH LINKED_ACCOUNT
        dispatch({
            type: GET_ACCOUNTS_FACEBOOK_SUCCESS,
            payload: dataFacebook
        })
        dispatch({
            type: LINKED_ACCOUNT,
            payload: resp3.data
        })

        localStorage.setItem("linkedAccount", JSON.stringify(resp3.data));

    } catch (error:any) {
        console.log("====> Error al validar cuentas");
        console.log(error.response);
        console.log(error.message);
        dispatch({
            type: GET_ACCOUNTS_FACEBOOK_ERROR,
            payload: "Error al vincular cuenta de Facebook"
        })

        setTimeout(() => {
            dispatch({
                type: GET_ACCOUNTS_FACEBOOK_ERROR,
                payload: null
            })

        }, 6000);
    }

}

export const getUserData = async (accessToken: string, dispatch: any, refreshToken: string) => {
    try {
        const resp = await clienteAxios.get('users/me', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        dispatch({
            type: GET_USER_DATA_SUCCESS,
            payload: resp.data
        })
        // Guardar en localStorage

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('profileData', JSON.stringify(resp.data));
        getInitialProyects(dispatch, accessToken, refreshToken, "START LOGIN");
    } catch (error:any) {
        console.log("====> GET USER DATA ERROR");
        console.log(error.response);
        dispatch({
            type: GET_USER_DATA_ERROR,
            payload: "No se pudó obtener tus datos."
        })
    }
}

export const recoverPassword = (email: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: RECOVER_PASSWORD
        })
        try {
            await clienteAxios.post('auth/forgot-password', {
                email,
            });
            dispatch({
                type: RECOVER_PASSWORD_SUCCESS,
                payload: true,
            })
            setTimeout(() => {
                dispatch({
                    type: RECOVER_PASSWORD_SUCCESS,
                    payload: false,
                })
            }, 6000)
        } catch (error) {
            console.log("==> Error al envíar Link");
            console.log(error);
            dispatch({
                type: RECOVER_PASSWORD_ERROR,
                payload: "Se produjo un error. Vuelve a intentarlo."
            })
        }


    }
}

export const updateUserData = (firstname: string, lastname: string, email: string, password: string = "", accessToken: string, id: string, setMessageOk: React.Dispatch<React.SetStateAction<string>>) => {
    return async (dispatch: any) => {
        dispatch({
            type: UPDATE_USER_ACCOUNT_DATA
        })
        try {
            const data: any = {
                firstname,
                lastname,
                email,
            }
            if (password !== "") data.password = password;

            await clienteAxios.patch(`/users/${id}`, data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            )
            const dateProfile = JSON.parse(localStorage.getItem('profileData')!);
            dateProfile.firstname = firstname;
            dateProfile.lastname = lastname;
            dateProfile.email = email;
            localStorage.setItem("profileData", JSON.stringify(dateProfile));

            // dateProfile.;
            dispatch({
                type: UPDATE_USER_ACCOUNT_DATA_SUCCESS,
                payload: data
            })
            setMessageOk("Datos actualizados con éxito");
            setTimeout(()=>{
                setMessageOk("");
            },2000)
        } catch (error:any) {
            console.log("===>Error in Update UserData")
            console.log(error);
            dispatch({
                type: UPDATE_USER_ACCOUNT_DATA_ERROR,
                payload: "No se pudo actualizar"
            })
        }
    }
}
export const desvincularCuentaFB = (accessToken: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: UNLINK_USER_ACCOUNT
        })
        try {
            await clienteAxios.delete('accounts/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            dispatch({
                type: UNLINK_USER_ACCOUNT_SUCCESS
            })
            // eLIMINAR DE LOCALSTROGAE
            localStorage.removeItem("linkedAccount");

        } catch (error) {
            console.log("==> error al desvincular")
            console.log(error);
            dispatch({
                type: UNLINK_USER_ACCOUNT_ERROR,
                payload: "Error al desvincular cuenta"
            })
        }
    }
}

export const resetearPassword = (email: string, token: string, password: string, passwordConfirmed: string, history: any) => {
    return async (dispatch: any) => {
        dispatch({
            type: RESET_PASSWORD
        })
        try {
            await clienteAxios.post("auth/recover-password", {
                email,
                token,
                password,
                passwordConfirmed
            })
            dispatch({
                type: RESET_PASSWORD_SUCCESS
            })

            setTimeout(() => {
                history('/login',{replace: true});
            }, 4000);


        } catch (error) {
            console.log("===> Error en reset Password");
            console.log(error)
            dispatch({
                type: RESET_PASSWORD_ERROR,
                payload: "No se pudo establecer una nueva contraseña"
            })
            setTimeout(() => {
                history('/login',{replace: true});

            }, 4000);
        }
    }
}


