import { ActionModel, IAuthState } from "../../interfaces/interface";
import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REFRESH_TOKEN,
    GET_ACCOUNTS_FACEBOOK,
    GET_ACCOUNTS_FACEBOOK_ERROR,
    GET_ACCOUNTS_FACEBOOK_SUCCESS,
    GET_ACCOUNTS_FACEBOOK_MULTIPLEPAGE_SUCCESS,
    CERRAR_SESION,
    RECOVER_PASSWORD,
    RECOVER_PASSWORD_ERROR,
    RECOVER_PASSWORD_SUCCESS,
    GET_USER_DATA_ERROR,
    GET_USER_DATA_SUCCESS,
    UPDATE_USER_ACCOUNT_DATA,
    UPDATE_USER_ACCOUNT_DATA_SUCCESS,
    UPDATE_USER_ACCOUNT_DATA_ERROR,
    LINKED_ACCOUNT,
    UNLINK_USER_ACCOUNT,
    UNLINK_USER_ACCOUNT_ERROR,
    UNLINK_USER_ACCOUNT_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS
} from "../../types/types";

const initialData: IAuthState = {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    // accessTokenFb: localStorage.getItem('accessTokenFb') || null,
    msgError: null,
    fetching: false,
    msgErrorRecover: null,
    msgSuccess: null,
    isSendLink: false,
    fbPages: [],
    isMultiplePages: false,
    profileData: JSON.parse(localStorage.getItem("profileData")!) || null,
    linkedAccount: JSON.parse(localStorage.getItem("linkedAccount")!) || null,
    fetchingFb: false,
    dataFb: false
}

export const authReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                fetching: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                msgError: null,
                // fetching: false
            };
        case LOGIN_ERROR:
            return {
                ...state,
                msgError: action.payload,
                accessToken: null,
                refreshToken: null,
                fetching: false
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken
            }
        case GET_ACCOUNTS_FACEBOOK:
            return {
                ...state,
                fetchingFb: true
            }
        case GET_ACCOUNTS_FACEBOOK_MULTIPLEPAGE_SUCCESS:
            return {
                ...state,
                fetchingFb: false,
                fbPages: action.payload.fbPages,
                dataFb: action.payload.dataFb,
                isMultiplePages: true,
                msgError: null,
            }
        case GET_ACCOUNTS_FACEBOOK_SUCCESS:
            return {
                ...state,
                fetchingFb: false,
                isMultiplePages: false,
                msgError: null
            }
        case GET_ACCOUNTS_FACEBOOK_ERROR:
            return {
                ...state,
                fetchingFb: false,
                msgError: action.payload,
                isMultiplePages: false,
            }
        case CERRAR_SESION:
            return {
                accessToken: null,
                refreshToken: null,
                msgError: null,
                fetching: false,
                fbPages: [],
                isMultiplePages: false,
                profileData: null
            };

        case RECOVER_PASSWORD:
            return {
                ...state,
                fetching: true,
                isSendLink: false
            }
        case RECOVER_PASSWORD_ERROR:
            return {
                ...state,
                fetching: false,
                msgErrorRecover: action.payload,
                isSendLink: false
            }
        case RECOVER_PASSWORD_SUCCESS:
            return {
                ...state,
                fetching: false,
                msgErrorRecover: null,
                isSendLink: action.payload
            }
        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                profileData: action.payload
            }
        case GET_USER_DATA_ERROR:
        case UPDATE_USER_ACCOUNT_DATA_ERROR:
            return {
                ...state,
                fetching: false,
                msgError: action.payload
            }
        case UPDATE_USER_ACCOUNT_DATA:
            return {
                ...state,
                fetching: false,
                msgError: null
            }
        case UPDATE_USER_ACCOUNT_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                profileData: {
                    ...state.profileData,
                    lastname: action.payload.lastname,
                    firstname: action.payload.firstname,
                    email: action.payload.email
                }
            }
        case LINKED_ACCOUNT:
            return {
                ...state,
                linkedAccount: action.payload
            }
        case UNLINK_USER_ACCOUNT:
            return {
                ...state,
                fetchingFb: true

            }
        case UNLINK_USER_ACCOUNT_ERROR:
            return {
                ...state,
                fetchingFb: false,
                msgError: action.payload
            }
        case UNLINK_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                fetchingFb: false,
                msgError: null,
                linkedAccount: null
            }
        case RESET_PASSWORD:
            return {
                ...state,
                fetching: true,
                msgSuccess: null,
                msgErrorRecover: null
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                fetching: false,
                msgSuccess: action.payload,
                msgErrorRecover: null
            }
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                fetching: false,
                msgSuccess: null,
                msgErrorRecover: action.payload
            }


        default:
            return state;
    }
}