import dangerIcon from "../../assets/icons/danger.svg"
import clockIcon from "../../assets/icons/clock.svg";
import chatboticon from "../../assets/icons/chatbot.svg";
const NoAvailable = () => {
    return (
        <div className="noavailable">
            <div className="noavailable-container">
                <div className="noavailable-container-title" >
                    <h3>Sitio <span>no</span> disponible</h3>
                </div>
                <div className="noavailable-container-icons" >
                    <div className="noavailable-icon1">
                        <img src={dangerIcon} alt="Alert" />
                    </div>
                    <div className="noavailable-icon2">
                        <img src={clockIcon} alt="time" />
                    </div>
                    <div className="noavailable-icon3">
                        <img src={chatboticon} alt="time" />
                    </div>
                </div>
                <div className="noavailable-container-description" >
                    <h4>Estamos trabajando en una nueva alternativa mejorada</h4>
                    <p>Tan pronto como la nueva alternativa esté disponible, serán los primeros en saberlo. Agradecemos su comprensión y paciencia durante este periodo de transición. </p>
                    <p>Estamos emocionados por el futuro y ansiosos de presentarles las mejoras que estamos preparando para ustedes.</p>
                </div>
            </div>
        </div>
    );
}

export default NoAvailable;