import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IStateModel } from '../../interfaces/interface';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};
Modal.setAppElement("#root");

function ModalNoAccount() {
  const history = useNavigate();
  const {
    auth: { linkedAccount },
  } = useSelector((state: IStateModel) => state);
  return (
    <Modal
      isOpen={linkedAccount ? false : true}
      onRequestClose={() => {}}
      style={customStyles}
      contentLabel="Crear Proyecto"
    >
      <div className="mh__modalAccount">
        <div className="mh__modalAccount-header">
          <h2>No tienes una cuenta de Facebook asociada</h2>
        </div>
        <div className="mh__modalAccount-description">
          <p>
            Es necesario que vincules una cuenta de Facebook para usar la
            herramienta. Hazlo ahora.{" "}
          </p>
          <div
            className="mh__modalAccount-btn"
            onClickCapture={() => history("/mi-cuenta")}
          >
            Ir a la página de perfil
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalNoAccount;
