import FormSearchHashtag from "../components/formSearchHashtag/FormSearchHashtag";
import HashtagsSelectedComponent from "../components/hashtagsSelected/hashtagsSelected";
import Header from "../components/headerComponents/header";
import InfoHashtagSelected from "../components/infoHashtagSelected/infoHashtagSelected";
import ModalNoAccount from "../components/modal-no-account/modalNoAccount";

function Home() {
  return (
    <>
      <ModalNoAccount />
      {/* Header */}
      <Header />
      <div className="mh__container">
        {/* Container hashtag seleccionado */}
        <HashtagsSelectedComponent />
        <div className="mh__content">
          <div className="mh__search-content">
            <FormSearchHashtag />
          </div>
          <div className="mh__content-hashtag">
            <InfoHashtagSelected />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
