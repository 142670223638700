import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { hashtagReducer } from '../reducers/hashtagReducers';
import { authReducer } from '../reducers/authReducer';
import { indexTabbarReducer } from '../reducers/indexTabbarReducer';
import { proyectsReducer } from '../reducers/proyectsReducer';
import thunk from 'redux-thunk';
import { getInitialProyects } from "../actions/proyectsAction";
import { verificarVinculacionCuenta } from "../actions/authAction";
import { filterReducer } from '../reducers/filterReducer';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const reducers = combineReducers({
    hashtags: hashtagReducer,
    auth: authReducer,
    tabbar: indexTabbarReducer,
    proyects: proyectsReducer,
    filtros: filterReducer
})


export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(thunk)
    )
)

// Recuperar los datos 
export default function generateStore() {
    let store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
    if (localStorage.getItem("accessToken") && localStorage.getItem("refreshToken")) {
        const accessToken = localStorage.getItem("accessToken") ?? '';
        const refreshToken = localStorage.getItem("refreshToken") ?? '';
        const dateA = Date.now();
        // const dateA = new Date(2021, 6, 29, 9, 55, 0);
        const dateLocal = Number.parseInt(localStorage.getItem("dateInitial")!) || null;
        if (!dateLocal) {
            localStorage.setItem("dateInitial", dateA.toString())
        } else {
            // Comparar si la fecha guardarda y la actual dan mas de 24 horas (de ser asi reiniciar searchs y actualizar fecha)
            // var difference = dateA.getTime() - dateLocal
            var difference = dateA - dateLocal
            const hours = (difference / (1000 * 60 * 60))
            // RESETEAR EN LA SEMANA
            if (hours >= 168 || hours >= 168.0) {
                localStorage.setItem("dateInitial", dateA.toString());
                localStorage.setItem("searchs", "0");
            }
        }
        verificarVinculacionCuenta(store.dispatch, accessToken);
        getInitialProyects(store.dispatch, accessToken, refreshToken, "store");
    }
    return store;
}
