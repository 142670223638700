import { ActionModel, IFiltros } from '../../interfaces/interface';
import {
    AGREGAR_FILTRO,
    ELIMINAR_FILTRO,
    CAMBIAR_ESTADO_OPEN,
    CHANGE_OPTION,
    CHANGE_PAGINATION,
    RESET_PAGINATION
} from "../../types/types";

const initialData: IFiltros = {
    misFiltros: [],
    isOpen: false,
    numberPagination: 5
}

export const filterReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case AGREGAR_FILTRO:
            return {
                ...state,
                misFiltros: [
                    ...state.misFiltros,
                    action.payload
                ]
            };
        case ELIMINAR_FILTRO:
            return {
                ...state,
                misFiltros: state.misFiltros.filter((filter: any) => filter.id !== action.payload),
                isOpen: state.misFiltros.length === 1 ? false : true
            };
        case CAMBIAR_ESTADO_OPEN:
            return {
                ...state,
                isOpen: !state.isOpen
            }
        case CHANGE_OPTION:
            return {
                ...state,
                misFiltros: action.payload
            }
        case CHANGE_PAGINATION:
            return {
                ...state,
                numberPagination: state.numberPagination + 5
            }
        case RESET_PAGINATION:
            return {
                ...state,
                numberPagination: 5
            }
        default:
            return state;
    }
}