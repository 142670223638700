import { ActionModel } from "../../interfaces/interface";
import {
    CHANGE_INDEX
} from "../../types/types";

const initialData = {
    index: 0
}

export const indexTabbarReducer = (state = initialData, action: ActionModel) => {
    switch (action.type) {
        case CHANGE_INDEX:
            return {
                index : action.payload
            };
        default:
            return state;
    }
}