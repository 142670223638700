// export const backendURL = 'localhost:4000/v1/api/';
// Producción
// export const backendURL ={
//     baseURL :'https://mas-hashtag-api.herokuapp.com/',
// }

export const backendURL = {
    // baseURL: 'http://localhost:4000/',
    // baseURL: 'https://mas-hashtag-api.herokuapp.com/',
    baseURL: 'https://apihashtags.triunfagram.com/',
}
export const facebookCredential = {
    // appkey : "413713946219040",
    appkey: "1342007772672305",
}
export const auditApi = {
    baseURL: 'https://apiaudit.triunfagram.com',
}
export const counterMaxUnicHashtags = 30;
// Desarrollo
// export const backendURL ={
//     baseURL :'localhost:4000/v1/api/',
// }