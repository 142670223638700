import clienteAxios from '../../config/axios'
import { counterMaxUnicHashtags } from '../../constants/constants';
import {
    CREATE_PROYECT,
    CREATE_PROYECT_SUCCESS,
    CREATE_PROYECT_ERROR,
    GET_PROYECTS,
    GET_PROYECTS_ERROR,
    GET_PROYECTS_SUCCESS,
    CHANGE_CURRENT_PROYECT,
    ADD_HASHTAG,
    ADD_HASHTAG_SUCCESS,
    ADD_HASHTAG_ERROR,
    REMOVE_HASHTAG_SUCCESS,
    REMOVE_ALL_HASHTAG_SUCCESS,
    // REMOVE_ALL_HASHTAG_ERROR,
    CERRAR_SESION,
    DELETE_HASHTAG,
    DELETE_HASHTAG_SUCCESS,
    DELETE_HASHTAG_ERROR
    // CHANGE_LIST_STATE_PROYECT

} from '../../types/types'
import { refreshToken } from "../actions/authAction"
import { resetListHashtag } from "./hashtagActions";


export const createProyect = (name: string, accessToken: string, closeModal: any, tokenRefresh: string,) => {
    return async (dispatch: any) => {
        dispatch({
            type: CREATE_PROYECT
        })
        try {
            const resp = await clienteAxios.post('/projects', {
                name
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );

            closeModal();
            dispatch({
                type: CREATE_PROYECT_SUCCESS,
                payload: resp.data
            })
            dispatch({
                type: CHANGE_CURRENT_PROYECT,
                payload: resp.data._id
            })
            getInitialProyects(dispatch, accessToken, tokenRefresh, "Create Proyect")

        } catch (error:any) {
            console.log("====> Error al Crear Proyecto");
            console.log(error.message);
            dispatch({
                type: CREATE_PROYECT_ERROR,
                payload: "Error al crear el Proyecto"
            })
        }

    }
}

export const changeCurrentProyect = (_id: string) => {
    return (dispatch: any) => {
        dispatch({
            type: CHANGE_CURRENT_PROYECT,
            payload: _id
        });
        if (localStorage.getItem(`${_id}`)) {
            const list = JSON.parse(localStorage.getItem(`${_id}`)!)
            let search_counter = Number.parseInt(localStorage.getItem("searchs")!) || 0;
            if (search_counter < counterMaxUnicHashtags) {
                resetListHashtag(list, dispatch);
            } else {
                resetListHashtag([], dispatch);
            }
        } else {
            resetListHashtag([], dispatch);
        }


    }
}

export const removeHashtag = (hashtag: string, currentProyect: any, accessToken: string, tokenRefresh: string) => {
    return async (dispatch: any) => {
        // dispatch({
        //     type: ADD_HASHTAG
        // })
        const list = currentProyect.hashtags;
        currentProyect.hashtags = list.filter((hashtagName: string) => hashtagName !== hashtag)
        try {
            const resp = await clienteAxios.patch(`/projects/${currentProyect._id}`, {
                name: currentProyect.name,
                hashtags: currentProyect.hashtags
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );

            dispatch({
                type: REMOVE_HASHTAG_SUCCESS,
                payload: resp.data
            });
            getInitialProyects(dispatch, accessToken, tokenRefresh, "Eliminar Hashtag")

        } catch (error) {
            console.log("===> Error al Elimina hashtag");
            console.log(error)
            dispatch({
                type: ADD_HASHTAG_ERROR,
                payload: "No se pudo Eliminar el hashtag"
            })
        }
    }
}
export const removeAllHashtags = (currentProyect: any, accessToken: string, tokenRefresh: string) => {
    return async (dispatch: any) => {
        // dispatch({
        //     type: ADD_HASHTAG
        // })
        currentProyect.hashtags = []
        try {
            const resp = await clienteAxios.patch(`/projects/${currentProyect._id}`, {
                name: currentProyect.name,
                hashtags: currentProyect.hashtags
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );
            dispatch({
                type: REMOVE_ALL_HASHTAG_SUCCESS,
                payload: resp.data
            });
            getInitialProyects(dispatch, accessToken, tokenRefresh, "REMOVE ALL HASHTAGS")

        } catch (error) {
            console.log("===> Error al Elimina hashtag");
            console.log(error)
            dispatch({
                type: ADD_HASHTAG_ERROR,
                payload: "No se pudo Eliminar el hashtag"
            })
        }
    }
}

export const addHashtag = (hashtag: string, currentProyect: any, accessToken: string, tokenRefresh: string, isEditModal: boolean = false, closeModal: any = () => { }) => {
    return async (dispatch: any) => {
        dispatch({
            type: ADD_HASHTAG
        })
        if (hashtag !== "") {
            currentProyect.hashtags.push(hashtag);
        }
        if (isEditModal) {
            closeModal();
        }
        try {
            const resp = await clienteAxios.patch(`/projects/${currentProyect._id}`, {
                name: currentProyect.name,
                hashtags: currentProyect.hashtags
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );
            dispatch({
                type: ADD_HASHTAG_SUCCESS,
                payload: resp.data
            });
            getInitialProyects(dispatch, accessToken, tokenRefresh, "ADDHASHTAG")

        } catch (error) {
            console.log("===> Error al agregar hashtag");
            console.log(error)
            dispatch({
                type: ADD_HASHTAG_ERROR,
                payload: "No se pudo agregar el hashtag"
            })
        }
    }
}

export let getInitialProyects = async (dispatch: any, accessToken: string, tokenRefresh: string, text: string) => {
    dispatch({
        type: GET_PROYECTS
    });
    try {
        const resp = await clienteAxios.get('/projects',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );
        dispatch({
            type: GET_PROYECTS_SUCCESS,
            payload: resp.data
        })

    } catch (error:any) {
        console.log("====> Error al Obtener Proyectos");
        console.log(error);
        console.log(error.message);
        if (error.response?.status === 401) {
            refreshToken(dispatch, tokenRefresh);
        }
        dispatch({
            type: GET_PROYECTS_ERROR,
            payload: (error.response?.status === 401) ? "No estas autorizado para esta acción" : "Error al obtener el Proyectos"
        })
    }
}
export const resetProyects = () => {
    return (dispatch: any) => {
        dispatch({
            type: CERRAR_SESION,
        });

    }
}

export const deleteProyect = (id: string, accessToken: string, tokenRefresh: string, closeModalConfirm: any, closeModal: any) => {
    return async (dispatch: any) => {
        dispatch({
            type: DELETE_HASHTAG
        })
        try {
            const resp = await clienteAxios.delete(`/projects/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );
            dispatch({
                type: DELETE_HASHTAG_SUCCESS,
                payload: resp.data
            })
            closeModal();
            closeModalConfirm();
            getInitialProyects(dispatch, accessToken, tokenRefresh, "Delete Proyect")
            dispatch(changeCurrentProyect(""));
            if (localStorage.getItem(`${id}`)) {
                localStorage.removeItem(`${id}`);
            }

        } catch (error) {
            console.log("===> Error al Eliminar Proyecto");
            console.log(error);
            dispatch({
                type: DELETE_HASHTAG_ERROR,
                payload: "No se pudo eliminar el proyecto"
            });
            closeModalConfirm();
        }
    }
}



