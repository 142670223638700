import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../assets/icons/ri_close-fill.svg";
import hashIcon from "../../assets/icons/hash.svg";
import { IStateModel, PropsModalCreateProyect } from "../../interfaces/interface";
import { createProyect } from "../../redux/actions/proyectsAction";
import { useState } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};
Modal.setAppElement("#root");

const ModalCreateProyect: React.FC<PropsModalCreateProyect> = ({
  modalIsOpen,
  closeModal,
}) => {
  const dispatch:any = useDispatch();
  const [name, setName] = useState("");

  const {
    auth: { accessToken, refreshToken },
    proyects: { error },
  } = useSelector((state: IStateModel) => state);

  const crearProyecto = () => {
    dispatch(createProyect(name, accessToken!,closeModal,refreshToken!));
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Crear Proyecto"
    >
      <div className="mh__modalCreateProyect-create">
        <div className="container-imgClose">
          <img
            src={closeIcon}
            alt="Cerrar ventana"
            onClickCapture={() => closeModal()}
          />
        </div>
        <div className="container-imgHash">
          <img src={hashIcon} alt="Más Hashtag" />
        </div>
        <h3>Crea un nuevo Proyecto</h3>
        <label htmlFor="name">Nombre de tu proyecto</label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Nombre"
          defaultValue={name}
          onChangeCapture={(e: any) => setName(e.target.value)}
        />
        {error ? (
          <div className="mh__modalCreateProyect-create-error">
            <p>{error}</p>
          </div>
        ) : (
          <></>
        )}
        <div className="mh__modalCreateProyect-create-row mh__modalCreateProyect-margin">
          <div onClickCapture={() => closeModal()}>
            <p>CANCELAR</p>
          </div>
          <div onClickCapture={() => crearProyecto()}>
            <p>CREAR PROYECTO</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateProyect;
