import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ForgotPassword from "../components/loginComponents/forgotPassword";
import Login from "../components/loginComponents/login";
import ResetPassword from "../components/loginComponents/resetPassword";
import NoAvailable from "../components/no-available/noAvailable";
import { IStateModel } from '../interfaces/interface';
import Account from "../views/Account";
import Announcement from "../views/Announcement";
import Home from "../views/MasHashtagsHome";
import { DashboardRoute } from "./DashboardRoute";

export const AppRouter = () => {
  const isAvailable = false;
  const {
    auth: { accessToken },
  } = useSelector((state: IStateModel) => state);

  return (
    <>
      {
        isAvailable ? <Router>
          <Routes>
            <Route path="/anuncio" element={<Announcement />} />
            <Route path="/login" element={!accessToken ? <Login /> : <Navigate to={'/'} />} />
            <Route path="/forgot-password" element={!accessToken ? <ForgotPassword /> : <Navigate to={'/'} />} />
            <Route path="/reset/:hash" element={!accessToken ? <ResetPassword /> : <Navigate to={'/'} />} />
            <Route path="/" element={accessToken ? <DashboardRoute /> : <Navigate to={"/login"} />} >
              <Route path="/mi-cuenta" element={<Account />} />
              <Route path="/" element={<Home />} />
            </Route>
          </Routes>
        </Router>
          :
          <Router>
            <Routes>
              <Route path="/anuncio" element={<Announcement />} />
              <Route path="/" element={<NoAvailable />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
          </Router>

        // <NoAvailable />
      }

    </>
  );
};
