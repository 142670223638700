
// OBTENER LOS HASHTAGS
export const GET_HASTAGS = 'GET_HASTAGS';
export const GET_HASTAGS_SUCCESS = 'GET_HASTAGS_SUCCESS';
export const GET_HASTAGS_EMPTY_SUCCESS = 'GET_HASTAGS_EMPTY_SUCCESS';
export const GET_HASTAGS_ERROR = 'GET_HASTAGS_ERROR';
// OBTENER INFORMACIÓN DE HASHTAG
export const GET_HASHTAG_INFO = "GET_HASHTAG_INFO";
export const GET_HASHTAG_INFO_SUCCESS = "GET_HASHTAG_INFO_SUCCESS";
export const GET_HASHTAG_INFO_ERROR = "GET_HASHTAG_INFO_ERROR";
// LOGIN
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
// ACCIONES EN AGREGAR HASHTAG A PROYECTO
export const ADD_HASHTAG = "ADD_HASHTAG";
export const ADD_HASHTAG_SUCCESS = "ADD_HASHTAG_SUCCESS";
export const ADD_HASHTAG_ERROR = "ADD_HASHTAG_ERROR";
export const REMOVE_HASHTAG_SUCCESS = "REMOVE_HASHTAG_SUCCESS";
export const REMOVE_HASHTAG_ERROR = "REMOVE_HASHTAG_ERROR";
export const REMOVE_ALL_HASHTAG_SUCCESS = "REMOVE_ALL_HASHTAG_SUCCESS";
export const REMOVE_ALL_HASHTAG_ERROR = "REMOVE_ALL_HASHTAG_ERROR";
export const RESET_LIST_HASHTAGS = "RESET_LIST_HASHTAGS"
// CAMBIO DE INDEX
export const CHANGE_INDEX = "CHANGE_INDEX"
// CREAR PROYECTO
export const CREATE_PROYECT = 'CREATE_PROYECT';
export const CREATE_PROYECT_SUCCESS = 'CREATE_PROYECT_SUCCESS';
export const CREATE_PROYECT_ERROR = 'CREATE_PROYECT_ERROR';
// OBTENER PROYECTOS
export const GET_PROYECTS = 'GET_PROYECTS';
export const GET_PROYECTS_SUCCESS = 'GET_PROYECTS_SUCCESS';
export const GET_PROYECTS_ERROR = 'GET_PROYECTS_ERROR';
// REFRESCAR TOKEN
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';
// SELECCIONAR PROYECTO
export const CHANGE_CURRENT_PROYECT = 'CHANGE_CURRENT_PROYECT';
// VALIDAR CUENTA DE FACEBOOM
export const GET_ACCOUNTS_FACEBOOK = "GET_ACCOUNTS_FACEBOOK"
export const GET_ACCOUNTS_FACEBOOK_SUCCESS = "GET_ACCOUNTS_FACEBOOK_SUCCESS"
export const GET_ACCOUNTS_FACEBOOK_MULTIPLEPAGE_SUCCESS = "GET_ACCOUNTS_FACEBOOK_MULTIPLEPAGE_SUCCESS"
export const GET_ACCOUNTS_FACEBOOK_ERROR = "GET_ACCOUNTS_FACEBOOK_ERROR"

// CERRAR SESIÓN
export const CERRAR_SESION = "CERRAR_SESION"

// CAMBIAR ULTIMA LISTA BUSCADA EN CADA PROYECTO
export const CHANGE_LIST_STATE_PROYECT = "CHANGE_LIST_STATE_PROYECT";

export const HASHTAGS_FROM_CAPTION = 'HASHTAGS_FROM_CAPTION';

// ELIMINAR PROYECTO
export const DELETE_HASHTAG = "DELETE_HASHTAG";
export const DELETE_HASHTAG_SUCCESS = "DELETE_HASHTAG_SUCCESS";
export const DELETE_HASHTAG_ERROR = "DELETE_HASHTAG_ERROR";
// SOLICITAR LINK RECUPERACION DE PASSWORD
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";
// OBTENER DATOS DE USUARIO
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";

// ACTUALIZAR DATOS DE LA CUENTA DEL USUARIO
export const UPDATE_USER_ACCOUNT_DATA = "UPDATE_USER_ACCOUNT_DATA";
export const UPDATE_USER_ACCOUNT_DATA_SUCCESS = "UPDATE_USER_ACCOUNT_DATA_SUCCESS";
export const UPDATE_USER_ACCOUNT_DATA_ERROR = "UPDATE_USER_ACCOUNT_DATA_ERROR";

// FILTROS
export const AGREGAR_FILTRO = "AGREGAR_FILTRO"
export const ELIMINAR_FILTRO = "ELIMINAR_FILTRO"
export const CAMBIAR_ESTADO_OPEN = "CAMBIAR_ESTADO_OPEN"
export const CHANGE_OPTION = "CHANGE_OPTION"

// DATOS DE CUENTA
export const LINKED_ACCOUNT = "LINKED_ACCOUNT"
// ACTUALIZAR DATOS DE LA CUENTA DEL USUARIO
export const UNLINK_USER_ACCOUNT = "UNLINK_USER_ACCOUNT";
export const UNLINK_USER_ACCOUNT_SUCCESS = "UNLINK_USER_ACCOUNT_SUCCESS";
export const UNLINK_USER_ACCOUNT_ERROR = "UNLINK_USER_ACCOUNT_ERROR";
// RECUPERAR PASSWORD
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
// NUMERO DE HASHTAGS UNICOS SOBREPASADO
export const COUNTER_SEARCHS_ERROR = "COUNTER_SEARCHS_ERROR";
// CAMBIAR PAGINACION
export const CHANGE_PAGINATION = "CHANGE_PAGINATION";
export const RESET_PAGINATION = "RESET_PAGINATION";
