import Header from "../components/headerComponents/header";
import FacebookLogin from "react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import {
  accountsFacebook,
  desvincularCuentaFB,
  validarCuenta,
} from "../redux/actions/authAction";
import { useState } from "react";
import { useFormRecoverPass } from "../hooks/useFormRecoverPass";
// import { ModelRecoverPass, IStateModel } from '../interfaces/interface';
import { facebookCredential } from "../constants/constants";
import SpinnerProgressIndicator from "../components/spinner/SpinnerCharging";
import iconfb from "../assets/icons/ri_facebook-circle-fill.svg";
import checkboxCopy from "../assets/icons/ri_checkbox-circle-line.svg";
import { IStateModel } from "../interfaces/interface";

function Account() {
  const dispatch: any = useDispatch();
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const {
    auth: {
      profileData,
      fetchingFb,
      accessToken,
      msgError,
      linkedAccount,
      isMultiplePages,
      fbPages,
      dataFb,
    },
  } = useSelector((state: IStateModel) => state);

  const accessTokenWeb = accessToken!;

  const { values, handleChange, onClickSend, handleBlur, messageOk } = useFormRecoverPass({
    password: "",
    rePassword: "",
    error: "",
    errorPass: "",
    firstname: `${profileData.firstname}`,
    lastname: `${profileData.lastname}`,
    correo: `${profileData.email}`,
  });

  const {
    password,
    rePassword,
    error,
    firstname,
    lastname,
    correo,
    errorPass,
  } = values;

  const responseFacebook = async (response: any) => {
    if (response.accessToken) {
      const { accessToken, name, picture } = response;
      const { data } = picture;
      const dataFb = {
        urlImg: data.url,
        name: name,
      };
      dispatch(accountsFacebook(accessToken, dataFb, accessTokenWeb));
    }
  };

  const openChangePassword = () => {
    setIsOpenChangePassword(!isOpenChangePassword);
  };
  const desvincularCuenta = () => {
    dispatch(desvincularCuentaFB(accessTokenWeb));
  };
  const seleccionarCuenta = (access_token: string, id: string) => {
    validarCuenta(access_token, id, dispatch, dataFb, accessTokenWeb);
  };

  return (
    <>
      {/* Header */}
      <Header />
      <div className="mh__container mh__account">
        {
          messageOk.length > 0 ?
            <div className="mh__hashtagsSelected-copy">
              <div className="mh__hashtagSelectd-copy-img">
                <img src={checkboxCopy} alt="copy" />
              </div>
              <p>{messageOk}</p>
            </div>
            : <></>
        }
        <h2 className="mh__account-title">Mi Cuenta</h2>
        <div className="mh__account-content">
          <div className="mh__facebook">
            <h3 className="mh__facebook-title">Cuenta de Facebook</h3>
            {isMultiplePages ? (
              fetchingFb ? (
                <div className="mh__account-spinner">
                  <SpinnerProgressIndicator />
                </div>
              ) : (
                <>
                  <p className="mh__facebook-card-title">
                    {" "}
                    Selecciona la página que quieres vincular
                  </p>
                  {fbPages.map((page: any) => {
                    return (
                      <div
                        className="mh__facebook-cards"
                        onClickCapture={() =>
                          seleccionarCuenta(page.access_token, page.id)
                        }
                      >
                        <p>{page.name}</p>
                        <p>Categoría: {page.category}</p>
                      </div>
                    );
                  })}
                </>
              )
            ) : linkedAccount ? (
              <>
                <div className="mh__facebook-account">
                  <div>
                    <img src={linkedAccount.image} alt="Profile Img" />
                  </div>
                  <p>{linkedAccount.name}</p>
                </div>
                {/* BTN DESVINCULAR */}
                {fetchingFb ? (
                  <div className="mh__account-spinner">
                    <SpinnerProgressIndicator />
                  </div>
                ) : (
                  <div
                    className="mh__account-btnDesvincular"
                    onClickCapture={() => desvincularCuenta()}
                  >
                    <div>
                      <img src={iconfb} alt="" />
                    </div>
                    {/* <div> */}
                    <p>Desvincular de facebook</p>
                    {/* </div> */}
                  </div>
                )}
              </>
            ) : (
              <>
                <p className="mh__facebook-subtitle">
                  Vincula tu cuenta de facebook para utilizar la herramienta.
                </p>
                {fetchingFb ? (
                  <div className="mh__account-spinner">
                    <SpinnerProgressIndicator />
                  </div>
                ) : (
                  <FacebookLogin
                    appId={facebookCredential.appkey}
                    autoLoad={false}
                    fields="name,email,picture"
                    isMobile={false}
                    disableMobileRedirect={true}
                    scope={
                      "instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,public_profile,email"
                    }
                    version={"12.0"}
                    callback={responseFacebook}
                    cssClass={"mh__facebook-button"}
                    textButton={"VINCULAR CON FACEBOOK"}
                  />
                )}
              </>
            )}
            {/* {} */}
            {msgError ? (
              <div className="mh__account-error">
                <p>{msgError}</p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="mh__account-info">
            <label htmlFor="firstname">Nombres</label>
            <input
              type="text"
              name="firstname"
              value={firstname}
              onChange={handleChange}
            />
            <label htmlFor="lastname">Apellidos</label>
            <input
              type="text"
              name="lastname"
              value={lastname}
              onChange={handleChange}
            />
            <label htmlFor="correo">correo</label>
            <input
              type="correo"
              name="correo"
              value={correo}
              onChange={handleChange}
            />
            {error !== "" ? (
              <div className="mh__account-passError">
                <p>{error}</p>
              </div>
            ) : (
              <></>
            )}
            <p onClickCapture={() => openChangePassword()}>
              {isOpenChangePassword ? "Cancelar" : "Cambiar contraseña"}
            </p>

            {isOpenChangePassword ? (
              <div className="mh__account-containerChange">
                <label htmlFor="newPass">Nueva Contraseña</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Escribe tu contraseña..."
                  value={password}
                  onChange={handleChange}
                />
                <label htmlFor="confirmNewPass">
                  Confirmar nueva Contraseña
                </label>
                <input
                  type="password"
                  name="rePassword"
                  id="rePassword"
                  placeholder="Repite tu contraseña..."
                  value={rePassword}
                  onChange={handleChange}
                  onBlurCapture={handleBlur}
                />
                {errorPass !== "" ? (
                  <div className="mh__account-passError">
                    <p>{errorPass}</p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="mh__account-info-btn" onClickCapture={onClickSend}>
              GUARDAR CAMBIOS
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Account;
