import logoBeta from "../assets/img/logo-beta.png"
const Announcement = () => {
    return (
        <div className="announcement">
            <div className="announcement-container">
                <div className="announcement-container-border">
                    <div className="announcement-border-1"></div>
                    <div className="announcement-border-2"></div>
                    <div className="announcement-border-3"></div>
                    <div className="announcement-border-4"></div>
                    <div className="announcement-border-5"></div>
                    <div className="announcement-border-6"></div>
                    <div className="announcement-border-7"></div>
                    <div className="announcement-border-8"></div>
                    <div className="announcement-border-9"></div>
                    <div className="announcement-border-10"></div>
                    <div className="announcement-border-11"></div>
                </div>
                <div className="announcement-container-title">
                    <img src={logoBeta} alt="Mas Hashtags" />
                    <p><b>Cierre de aplicaciones</b> a partir del <b>19 de febrero</b></p>
                </div>
                <div className="announcement-container-description">
                    <p> Con pesar, anunciamos que nuestras operaciones actuales dejarán de estar disponibles a partir del <b>19 de febrero</b>, debido a que estamos dedicando esfuerzos en el desarrollo de una nueva versión mejorada. <br /><br />
                        Nuestro compromiso es ofrecerles una experiencia superior y, en este proceso de mejora, estamos enfocados en incorporar nuevas funcionalidades y optimizaciones que enriquecerán su interacción con nosotros. Tan pronto como la nueva versión esté disponible, serán los primeros en saberlo. Agradecemos su comprensión y paciencia durante este periodo de transición.
                        <br /><br />
                        Estamos emocionados por el futuro y ansiosos de presentarles las mejoras que estamos preparando para ustedes.</p>

                </div>
            </div>
        </div>
    );
}

export default Announcement;